import { createStyles } from '@mantine/core';

const styles = createStyles(theme => ({
  root: {
    marginTop: '20px',
    display: 'grid',
    gridTemplateColumns: '5fr 2fr 2fr 2fr',
    alignItems: 'flex-end',
    background: `linear-gradient(${theme.colors[theme.primaryColor][7]}, ${theme.colors[theme.primaryColor][3]})`,
    padding: '20px',
    borderRadius: '10px',

    '@media screen and (max-width: 1500px)': {
      gridTemplateColumns: '3fr 1fr 1fr 1fr',
    },
  },

  count: {
    color: 'white',
    fontSize: 32,
    lineHeight: 1,
    fontWeight: 700,
    marginBottom: '15px',
  },

  countBigger: {
    color: 'white',
    fontSize: 40,
    lineHeight: 1,
    fontWeight: 700,
    marginBottom: '15px',
  },

  title: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },

  titleBigger: {
    color: 'white',
    fontSize: 28,
    textTransform: 'uppercase',
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },

  stat: {
    flex: 1,
    height: '100%',

    '& + &': {
      padding: '0 20px',
      borderLeft: '1px solid white',
    },
  },
}));

export default styles;

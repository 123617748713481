import logo from '@assets/logos/logo.svg';
import { useAuthContext } from '@modules/auth/context';
import { Profile } from '@modules/auth/model';
import { sequenceT } from 'fp-ts/Apply';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from './Nav.styles';
import { ActionIcon, Anchor, Navbar, Text } from '@mantine/core';
import { Logout } from 'tabler-icons-react';
import { useStyles } from '@styles/hooks';

interface NavEntry {
  title: string;
  to: string;
}

interface NavGroup {
  title: string;
  entries: Array<NavEntry>;
}

function getProfileName(profile: Profile): string {
  return pipe(
    sequenceT(O.Apply)(O.fromNullable(profile.firstName), O.fromNullable(profile.lastName)),
    O.fold(
      () => profile.email,
      ([firstName, lastName]) => `${firstName} ${lastName}`,
    ),
  );
}

const entries: Array<NavGroup> = [
  {
    title: 'SAV',
    entries: [
      { title: 'Tableau de bord', to: '/' },
      { title: 'Utilisateurs SAV', to: '/customer-users' },
      { title: 'Diagnostics', to: '/diagnostics' },
    ],
  },
  {
    title: 'Administration',
    entries: [{ title: 'Utilisateurs BO', to: '/admin-users' }],
  },
];

interface NavProps {
  profile: Profile;
}

const Nav: FC<NavProps> = ({ profile }) => {
  const { logout } = useAuthContext();

  const { classes } = useStyles(styles, Nav, undefined);

  return (
    <>
      <Navbar width={{ base: 270 }}>
        <Navbar.Section className={classes.header}>
          <Link to="/">
            <img src={logo} alt="logo" width="100px" />
          </Link>
        </Navbar.Section>

        <Navbar.Section grow className={classes.content}>
          {entries.map((group, i) => (
            <div key={i} className={classes.group}>
              <p>{group.title}</p>

              <ul className={classes.entries}>
                {group.entries.map((entry, j) => (
                  <li key={j}>
                    <NavLink to={entry.to} className={({ isActive }) => (isActive ? ' active' : '')}>
                      <div>
                        <span>{entry.title}</span>
                      </div>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Navbar.Section>

        <Navbar.Section className={classes.footer} p="md">
          <Anchor component={Link} to="/profile">
            <Text size="sm" weight="600">
              {getProfileName(profile)}
            </Text>
          </Anchor>

          <ActionIcon variant="light" color="green" onClick={logout}>
            <Logout size="16" />
          </ActionIcon>
        </Navbar.Section>
      </Navbar>
    </>
  );
};

export default Nav;

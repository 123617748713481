import React, { FC } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';

import { safeLazy } from '@core/router';
import Layout from '@layout/Layout';
import { renderOptional } from '@shared/utils/render';
import { useAuthContext } from '@modules/auth/context';
import { Routes } from '@core/router/Routes';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { HttpError } from '@core/http';
import DashboardRoutes from '@modules/dashboard/routes';

const LoginPage = safeLazy(() => import('@modules/auth/LoginPage'));
const ForgotPasswordPage = safeLazy(() => import('@modules/auth/ForgotPasswordPage'));
const ResetPasswordPage = safeLazy(() => import('@modules/auth/ResetPasswordPage'));
const ActivationPage = safeLazy(() => import('@modules/auth/ActivationPage'));

const ProfilePage = safeLazy(() => import('@modules/auth/ProfilePage'));

const CustomerUsersRoutes = safeLazy(() => import('@modules/customer-users/routes'));
const AdminUsersRoutes = safeLazy(() => import('@modules/admin-users/routes'));
const DiagnosticsRoutes = safeLazy(() => import('@modules/diagnostics/routes'));

const PrivateRoutes: FC = () => {
  const { profile } = useAuthContext();

  const location = useLocation();

  return renderOptional(
    profile,
    profile => (
      <Routes>
        <Route element={<Layout profile={profile} />}>
          <Route index element={<DashboardRoutes />} />

          <Route path="/profile" element={<ProfilePage profile={profile} />} />

          <Route path="/customer-users/*" element={<CustomerUsersRoutes />} />

          <Route path="/diagnostics/*" element={<DiagnosticsRoutes />} />

          <Route path="/admin-users/*" element={<AdminUsersRoutes />} />

          <Route path="*" element={<ErrorPage error={HttpError.notFound} />} />
        </Route>
      </Routes>
    ),
    () => <Navigate to="/login" replace state={{ referrer: location }} />,
  );
};

const RootRoutes: FC = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/password-reset/forgot" element={<ForgotPasswordPage />} />
    <Route path="/password-reset/:token" element={<ResetPasswordPage />} />
    <Route path="/activation/:token" element={<ActivationPage />} />

    <Route path="*" element={<PrivateRoutes />} />
  </Routes>
);

export default RootRoutes;

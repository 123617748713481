import { httpService, HttpTask } from '@core/http';
import { Dashboard, DashboardPeriod } from '@modules/dashboard/model';
import { Product } from '@modules/diagnostics/model';

const URI = '/dashboard';

export function getDashboard(product: string | null, period: DashboardPeriod): HttpTask<Dashboard> {
  return httpService.get(URI, { params: { product, period } });
}

export function searchProduct(search: string | null): HttpTask<Array<Product>> {
  return httpService.get(`${URI}/products`, { params: search });
}

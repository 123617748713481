export interface DashboardDiagnosticStatsParams {
  currently: number;
  lastPeriod: number;
  total: number;
}

export interface DashboardDiagnosticStats {
  launchedDiagnostics: DashboardDiagnosticStatsParams;
  averageScores: DashboardDiagnosticStatsParams;
}

export interface DashboardUserStats {
  totalUser: number;
  totalRetailer: number;
  totalUserCurrentMonth: number;
  totalUserCurrentYear: number;
}

export interface Dashboard {
  diagnosticStats: DashboardDiagnosticStats;
  userStats: DashboardUserStats;
}

export enum DashboardPeriod {
  CurrentMonthPreviousMonth = 'current-month-previous-month',
  CurrentMonthPreviousYear = 'current-month-previous-year-same-month',
  CurrentYearLastYear = 'current-year-last-year',
}

export const dashboardPeriodLabel: Record<DashboardPeriod, string> = {
  [DashboardPeriod.CurrentMonthPreviousMonth]: 'Mois courant versus mois dernier',
  [DashboardPeriod.CurrentMonthPreviousYear]: 'Ce mois-ci versus même mois année N-1',
  [DashboardPeriod.CurrentYearLastYear]: 'Cette année versus année précédente',
};

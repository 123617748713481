import { constVoid, Lazy, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import { Profile } from '@modules/auth/model';

import * as AuthService from './service';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';

const AUTH_LOGOUT_KEY = 'PG_LOGOUT';

export interface AuthContextValue {
  profile: O.Option<Profile>;
  updateProfile: (profile: Profile) => void;
  requestUpdateProfile: Lazy<void>;
  logout: T.Task<void>;
}

export const AuthContext = createContext<AuthContextValue>({
  profile: O.none,
  updateProfile: constVoid,
  requestUpdateProfile: constVoid,
  logout: T.never,
});

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);

  const [profile, setProfile] = useState<O.Option<Profile>>(O.none);

  const fetchProfile = useCallback(() => {
    pipe(
      AuthService.getProfile(),
      T.chainIOK(res => () => {
        setProfile(O.fromEither(res));
        setLoading(false);
      }),
    )();
  }, []);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === AUTH_LOGOUT_KEY) {
        setProfile(O.none);
      }
    };

    window.addEventListener('storage', syncLogout, { passive: true });

    return () => {
      window.removeEventListener('storage', syncLogout);
      localStorage.removeItem(AUTH_LOGOUT_KEY);
    };
  }, [navigate]);

  const updateProfile = useCallback((profile: Profile) => setProfile(O.some(profile)), []);

  const logout = useCallback(() => {
    return pipe(
      AuthService.logout(),
      T.chainIOK(() => () => {
        localStorage.setItem(AUTH_LOGOUT_KEY, Date.now().toString());
        navigate('/login', { replace: true, state: { ignorePrevent: true } });
        setProfile(O.none);
      }),
    )();
  }, [navigate]);

  const ctx = useMemo<AuthContextValue>(
    () => ({
      profile,
      updateProfile,
      requestUpdateProfile: fetchProfile,
      logout,
    }),
    [profile, updateProfile, fetchProfile, logout],
  );

  return (
    <SentryProvider profile={profile}>
      <AuthContext.Provider value={ctx}>{loading ? <DebouncedLineLoader /> : children}</AuthContext.Provider>
    </SentryProvider>
  );
};

export function useAuthContext(): AuthContextValue {
  return useContext(AuthContext);
}

import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';

const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider
      theme={{
        fontFamily: 'Open Sans, sans-serif',
        colors: {
          green: [
            '#e8f5ee',
            '#71c9a8',
            '#5bb08e',
            '#46886f',
            '#00664b',
            '#09553f',
            '#386d58',
            '#284f3f',
            '#1f3a2f',
            '#1f3a2f',
          ],
          yellow: [
            '#fdf0db',
            '#fce1b7',
            '#fad294',
            '#f7b44c',
            '#f5a528',
            '#f4990c',
            '#e0931b',
            '#cc8a21',
            '#a36e1b',
            '#a36e1b',
          ],
        },
        primaryColor: 'green',
        primaryShade: 4,
        components: {
          Papper: {
            styles: {
              root: {
                boxShadow: '0 0 10px 0 rgb(0 0 0 / 4%)',
              },
            },
          },
          InputWrapper: {
            styles: {
              label: {
                fontWeight: 500,
              },
            },
          },
          Modal: {
            defaultProps: {
              overlayColor: 'grey',
              overlayOpacity: 0.5,
              overlayBlur: 1,
            },
          },
        },
      }}
      withNormalizeCSS
      withGlobalStyles
    >
      <NotificationsProvider position="top-right">{children}</NotificationsProvider>
    </MantineProvider>
  );
};

export default MantineTheme;

import React, { FC, useState } from 'react';
import * as DashboardService from '@modules/dashboard/service';
import Page, { PageProps } from '@layout/page/Page';
import { useFetchTask } from '@core/http/hooks';
import { DashboardPeriod } from '@modules/dashboard/model';
import { renderHttpRemoteData } from '@shared/utils/render';
import DashboardUsers from '@modules/dashboard/components/users/DashboardUsers';
import DashboardDiagnostics from '@modules/dashboard/components/diagnostics/DashboardDiagnostics';
import DashboardFilters from '@modules/dashboard/components/filters/DashboardFilters';
import { Box, Button, Group, Modal, Text } from '@mantine/core';
import { useBooleanState } from '@shared/hooks/boolean';
import { Upload } from 'tabler-icons-react';

const DashboardPage: FC = () => {
  const [isOpen, openModal, closeModal] = useBooleanState(false);
  const [productFilter, setProductFilter] = useState<string | null>(null);
  const [periodFilter, setPeriodFilter] = useState<DashboardPeriod>(DashboardPeriod.CurrentMonthPreviousMonth);

  const [dashboard] = useFetchTask(DashboardService.getDashboard, productFilter, periodFilter);
  const [products] = useFetchTask(DashboardService.searchProduct, null);

  const handleChangeProductFilter = (product: string | null) => {
    setProductFilter(product);
  };

  const handleChangePeriodFilter = (period: DashboardPeriod) => {
    setPeriodFilter(period);
  };

  const handleOpenModal = () => {
    openModal();
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const pageProps: PageProps = {
    breadcrumbs: { title: 'Tableau de bord' },
    bottom: {
      right: (
        <Button onClick={handleOpenModal} leftIcon={<Upload color="white" />}>
          Exporter les stats
        </Button>
      ),
    },
  };

  return renderHttpRemoteData(dashboard, dashboard => (
    <Page {...pageProps}>
      {renderHttpRemoteData(products, products => (
        <DashboardFilters
          products={products}
          productFilter={productFilter}
          onChangeProductFilter={handleChangeProductFilter}
          periodFilter={periodFilter}
          onChangePeriodFilter={handleChangePeriodFilter}
        />
      ))}

      <DashboardDiagnostics dashboardDiagnostics={dashboard.diagnosticStats} />

      <DashboardUsers dashboardUser={dashboard.userStats} />

      <Modal
        opened={isOpen}
        onClose={handleCloseModal}
        title={
          <Text size="lg" weight={600}>
            Choix de l'export
          </Text>
        }
        centered
        size="lg"
      >
        <Box
          sx={{
            padding: '15px 20px 0 20px',
            margin: '0 -20px 20px',
            borderTop: '1px solid #ddd',
          }}
        >
          <Text>Quel export de statistiques souhaitez-vous ?</Text>

          <Group mt="lg">
            <a
              href="back/dashboard/diagnostic-tracks"
              rel="noreferrer noopener"
              style={{ textDecoration: 'none', color: 'white' }}
            >
              <Button onClick={handleCloseModal}>Diagnostics</Button>
            </a>

            <a
              href="back/dashboard/customers"
              rel="noreferrer noopener"
              style={{ textDecoration: 'none', color: 'white' }}
            >
              <Button onClick={handleCloseModal}>Comptes utilisateurs SAV</Button>
            </a>
          </Group>
        </Box>
      </Modal>
    </Page>
  ));
};

export default DashboardPage;

import React, { FC } from 'react';
import { Routes } from '@core/router/Routes';
import { Route } from 'react-router-dom';
import DashboardPage from '@modules/dashboard/DashboardPage';

const DashboardRoutes: FC = () => (
  <Routes>
    <Route index element={<DashboardPage />} />
  </Routes>
);

export default DashboardRoutes;

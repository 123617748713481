import React, { FC } from 'react';
import { DashboardDiagnosticStats, DashboardDiagnosticStatsParams } from '@modules/dashboard/model';
import { Col, Divider, Grid, Group, Paper, RingProgress, Text, useMantineTheme } from '@mantine/core';
import { ArrowNarrowRight } from 'tabler-icons-react';

interface EvolType {
  color: string;
  rotation?: string;
}

function getEvol(scores: DashboardDiagnosticStatsParams): EvolType | null {
  if (scores.currently && scores.lastPeriod) {
    if (scores.currently > scores.lastPeriod)
      return {
        color: 'green',
        rotation: '-45deg',
      };
    else if (scores.currently < scores.lastPeriod)
      return {
        color: 'red',
        rotation: '45deg',
      };

    return {
      color: 'gray',
    };
  }
  return null;
}

interface DashboardDiagnosticsProps {
  dashboardDiagnostics: DashboardDiagnosticStats;
}

const DashboardDiagnostics: FC<DashboardDiagnosticsProps> = ({ dashboardDiagnostics }) => {
  const theme = useMantineTheme();

  const evol = getEvol(dashboardDiagnostics.averageScores);

  return (
    <Paper p="lg" mt="md">
      <Grid>
        <Col span={4} />
        <Col span={3} style={{ display: 'flex', justifyContent: 'center', fontWeight: 700 }}>
          N
        </Col>
        <Col span={3} style={{ display: 'flex', justifyContent: 'center', fontWeight: 700 }}>
          N-1
        </Col>
        <Col span={2} style={{ display: 'flex', justifyContent: 'center', fontWeight: 700 }}>
          Total
        </Col>
      </Grid>

      <Divider my="sm" />

      <Grid pt="xl">
        <Col span={4}>
          <Text size="xl" weight={700}>
            Nombre de diagnostics démarrés
          </Text>
        </Col>

        <Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text size="xl" weight={700}>
            {dashboardDiagnostics.launchedDiagnostics.currently}
          </Text>
        </Col>

        <Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text size="xl" weight={700}>
            {dashboardDiagnostics.launchedDiagnostics.lastPeriod}
          </Text>
        </Col>

        <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text size="xl" weight={700}>
            {dashboardDiagnostics.launchedDiagnostics.total}
          </Text>
        </Col>
      </Grid>

      <Grid style={{ minHeight: 136 }}>
        <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Text size="xl" weight={700}>
            Note moyenne de résolution de diagnostic
          </Text>
        </Col>

        <Col
          span={3}
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 700,
          }}
        >
          {dashboardDiagnostics.averageScores.currently != null ? (
            <RingProgress
              sections={[{ value: dashboardDiagnostics.averageScores.currently * 20, color: 'green' }]}
              label={
                <Text weight={700} align="center" size="xl" color={theme.colors[theme.primaryColor][7]}>
                  {dashboardDiagnostics.averageScores.currently} / 5
                </Text>
              }
            />
          ) : (
            <Text>Aucune donnée</Text>
          )}

          {evol != null ? (
            <Group style={{ position: 'absolute', left: '50%', transform: 'translateX(50px)' }}>
              <ArrowNarrowRight style={{ color: evol.color, transform: `rotate(${evol.rotation})` }} size={60} />
            </Group>
          ) : null}
        </Col>

        <Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 700 }}>
          {dashboardDiagnostics.averageScores.lastPeriod != null ? (
            <RingProgress
              sections={[{ value: dashboardDiagnostics.averageScores.lastPeriod * 20, color: 'green' }]}
              label={
                <Text weight={700} align="center" size="xl" color={theme.colors[theme.primaryColor][7]}>
                  {dashboardDiagnostics.averageScores.lastPeriod} / 5
                </Text>
              }
            />
          ) : (
            <Text>Aucune donnée</Text>
          )}
        </Col>

        <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 700 }}>
          {dashboardDiagnostics.averageScores.total != null ? (
            <RingProgress
              sections={[{ value: dashboardDiagnostics.averageScores.total * 20, color: 'green' }]}
              label={
                <Text weight={700} align="center" size="xl" color={theme.colors[theme.primaryColor][7]}>
                  {dashboardDiagnostics.averageScores.total} / 5
                </Text>
              }
            />
          ) : (
            <Text>Aucune donnée</Text>
          )}
        </Col>
      </Grid>
    </Paper>
  );
};

export default DashboardDiagnostics;

import React, { FC } from 'react';
import { Product } from '@modules/diagnostics/model';
import { DashboardPeriod, dashboardPeriodLabel } from '@modules/dashboard/model';
import { Group, Paper, Select } from '@mantine/core';

const dashboardPeriodData = [
  {
    label: dashboardPeriodLabel[DashboardPeriod.CurrentMonthPreviousMonth],
    value: DashboardPeriod.CurrentMonthPreviousMonth,
  },
  {
    label: dashboardPeriodLabel[DashboardPeriod.CurrentMonthPreviousYear],
    value: DashboardPeriod.CurrentMonthPreviousYear,
  },
  {
    label: dashboardPeriodLabel[DashboardPeriod.CurrentYearLastYear],
    value: DashboardPeriod.CurrentYearLastYear,
  },
];

function getDashboardProductsData(products: Array<Product>): Array<string> {
  return products.map(product => product.product);
}

interface DashboardFiltersProps {
  products: Array<Product>;
  productFilter: string | null;
  onChangeProductFilter: (product: string | null) => void;
  periodFilter: DashboardPeriod;
  onChangePeriodFilter: (period: DashboardPeriod) => void;
}

const DashboardFilters: FC<DashboardFiltersProps> = ({
  products,
  productFilter,
  onChangeProductFilter,
  periodFilter,
  onChangePeriodFilter,
}) => {
  const handleChangeProduct = (val: string | null) => {
    onChangeProductFilter(val);
  };

  const handleChangePeriod = (val: DashboardPeriod) => {
    onChangePeriodFilter(val);
  };

  const dashboardProductsData = getDashboardProductsData(products);

  return (
    <Paper p="lg">
      <Group>
        <Select
          placeholder="Produit"
          data={dashboardProductsData}
          onChange={handleChangeProduct}
          value={productFilter}
          clearable
          sx={{ width: 300 }}
        />

        <Select
          placeholder="Période"
          data={dashboardPeriodData}
          onChange={handleChangePeriod}
          value={periodFilter}
          sx={{ width: 300 }}
        />
      </Group>
    </Paper>
  );
};

export default DashboardFilters;

import React, { FC } from 'react';
import styles from './DashboardUsers.styles';
import { useStyles } from '@styles/hooks';
import { DashboardUserStats } from '@modules/dashboard/model';
import { Text } from '@mantine/core';

interface DashboardUsersProps {
  dashboardUser: DashboardUserStats;
}

const DashboardUsers: FC<DashboardUsersProps> = ({ dashboardUser }) => {
  const { classes } = useStyles(styles, DashboardUsers, undefined);

  return (
    <div className={classes.root}>
      <div className={classes.stat}>
        <Text className={classes.countBigger}>{dashboardUser.totalUser}</Text>
        <Text className={classes.titleBigger}>Comptes utilisateurs SAV créés</Text>
      </div>

      <div className={classes.stat}>
        <Text className={classes.count}>{dashboardUser.totalRetailer}</Text>
        <Text className={classes.title}>Revendeurs</Text>
      </div>

      <div className={classes.stat}>
        <Text className={classes.count}>{dashboardUser.totalUserCurrentMonth}</Text>
        <Text className={classes.title}>Ce mois-ci</Text>
      </div>

      <div className={classes.stat}>
        <Text className={classes.count}>{dashboardUser.totalUserCurrentYear}</Text>
        <Text className={classes.title}>Cette année</Text>
      </div>
    </div>
  );
};

export default DashboardUsers;

import { createStyles } from '@mantine/core';

const styles = createStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  top: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 71,
    padding: '0 30px',
    background: theme.white,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  topLeft: {
    flex: '1 1 auto',
  },

  contentWrapper: {
    flex: '1 1 auto',
    width: '100%',
    overflowY: 'auto',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: 20,
    margin: '0 auto',
  },

  children: {
    flex: '1 1 auto',
    paddingBottom: 20,
  },

  bottom: {
    minHeight: 61,
    background: theme.white,
    borderTop: `1px solid ${theme.colors.gray[2]}`,

    '> div': {
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0 auto',
      padding: '0 15px',

      '> div': {
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'center',
        padding: '7px 0',

        '&.left': {
          justifyContent: 'flex-start',
        },

        '&.right': {
          justifyContent: 'flex-end',
        },
      },
    },
  },
}));

export default styles;

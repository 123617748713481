import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import Seo from '@shared/modules/seo/Seo';
import { renderOptional } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Link, NavLink, resolvePath, To, useLocation } from 'react-router-dom';
import styles from './Page.styles';
import { Anchor, Breadcrumbs, Paper, Tabs, Text } from '@mantine/core';
import { ChevronRight } from 'tabler-icons-react';
import { useStyles } from '@styles/hooks';

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface Tab {
  title: string;
  to: To;
  exact?: boolean;
}

export interface Breadcrumb {
  title: ReactNode;
  displayTitle?: string;
  to?: To;
}

export interface BottomBarProps {
  left?: ReactNode;
  right?: ReactNode;
}

export interface PageProps {
  breadcrumbs?: Breadcrumb | Array<Breadcrumb>;
  tabs?: Array<Tab>;
  bottom?: BottomBarProps;
  loading?: boolean;
}

const PageTabs: FC<{ tabs?: Array<Tab> }> = ({ tabs }) => {
  const location = useLocation();

  if (tabs && tabs.length) {
    return (
      <Paper>
        <Tabs value={location.pathname}>
          <Tabs.List>
            {tabs.map((tab, i) => (
              <Tabs.Tab value={resolvePath(tab.to).pathname} key={i} sx={{ padding: 0 }}>
                <Anchor component={NavLink} to={tab.to} sx={{ display: 'block', padding: '10px 20px' }}>
                  {tab.title}
                </Anchor>
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
      </Paper>
    );
  }

  return null;
};

const PageBottomBar: FC<{ bottom?: BottomBarProps }> = ({ bottom }) => {
  const { classes } = useStyles(styles, PageBottomBar, undefined);

  const optBottom = O.fromNullable(bottom);

  const leftContent = pipe(
    optBottom,
    O.chainNullableK(b => b.left),
  );

  const rightContent = pipe(
    optBottom,
    O.chainNullableK(b => b.right),
  );

  if (O.isSome(leftContent) || O.isSome(rightContent)) {
    return (
      <div className={classes.bottom}>
        <div>
          <div className="left">{O.toNullable(leftContent)}</div>
          <div className="right">{O.toNullable(rightContent)}</div>
        </div>
      </div>
    );
  }

  return null;
};

const Page: FC<PropsWithChildren<PageProps>> = ({ breadcrumbs, tabs, bottom, loading, children }) => {
  const { classes } = useStyles(styles, Page, undefined);

  const breadcrumbsOpt = pipe(
    O.fromNullable(breadcrumbs),
    O.map(breadcrumbs => (Array.isArray(breadcrumbs) ? breadcrumbs : [breadcrumbs])),
  );

  const seoTitle: string | undefined = pipe(
    breadcrumbsOpt,
    O.map(breadcrumbs => breadcrumbs.map(b => b.displayTitle ?? b.title).join(' - ')),
    O.toUndefined,
  );

  return (
    <div className={classes.container}>
      <Seo title={seoTitle} />

      {renderOptional(breadcrumbsOpt, breadcrumbs => (
        <div className={classes.top}>
          <div className={classes.topLeft}>
            <Breadcrumbs separator={<ChevronRight size={20} />} style={{ alignItems: 'center' }}>
              {breadcrumbs.map((item, i) =>
                item.to ? (
                  <Anchor key={i} component={Link} to={item.to} weight="500">
                    {item.title}
                  </Anchor>
                ) : (
                  <Text key={i}>{item.title}</Text>
                ),
              )}
            </Breadcrumbs>
          </div>
        </div>
      ))}

      {loading && <DebouncedLineLoader />}

      <PageTabs tabs={tabs} />

      <div className={classes.contentWrapper} id={PAGE_SCROLLER_ID}>
        <div className={classes.content}>
          <div className={classes.children}>{children}</div>
        </div>
      </div>

      <PageBottomBar bottom={bottom} />
    </div>
  );
};

export default Page;
